import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HasError from "src/common/components/hasError/HasError";
import {
  deleteWidgetFromDashboard,
  geAvgMonthCompanyCreation,
  geTopMonthCompanyCreation,
  geTotalTaskCreation,
  getAvgMonthTask,
  getCloseAfter,
  getCloseBefore,
  getCreatedLast24,
  getDashboardLists,
  getFiscalYear,
  getKpiAchievedThisMonth,
  getKpiAchievedTillDate,
  getKpiBacklogTillDate,
  getKpiTotalCompanyTarget,
  getLastWeekCompanyCreated,
  getLastWeekTotalTaskCount,
  getLowestAmount,
  getMaxDealAmount,
  getTicketClosedLast24,
  getTicketCreated,
  getTicketResolveTime,
  getTopMonthTask,
  getTotalClosedTicket,
  getTotalCompanyCreated,
  getTotalOpenTicket,
  getTotalProgressedTicket,
  getTotalTicket,
  moveReport,
  renameReport,
} from "src/common/analytics-and-dashboard-api-calls";

import {
  ILayoutsPNew,
  IWidgetInfo,
  analyticsEndpoints,
} from "src/common/types/analytics.types";
import SnippetsHeaderActions from "src/micro/snippets/SnippetsHeaderActions";
import { Button, Divider, Input, Modal, SelectPicker } from "rsuite";
import FilterModal from "src/components/FilterModals/FilterModal";

import "./singleCard.scss";
import { getLastNDaysDate } from "src/common/helpers/CustomDateHelper";
import LoadingComponent from "src/common/components/loading/Loading";
import toast from "react-hot-toast";
import { GetALLUsers, getTeamsOfAnUser } from "src/common/api-common";
import { getAccountId, getUserId } from "src/common/constants";
interface IVerticalLineChart {
  title?: string;
  endPoint?: string;
  source?: string;
  description?: string;
  widgetData?: IWidgetInfo;
  showFilter?: boolean;
  layoutsPassToTemplate?: ILayoutsPNew;
}

const SingleCard: React.FC<IVerticalLineChart> = ({
  title,
  endPoint,
  source,
  description,
  widgetData,
  showFilter,
  layoutsPassToTemplate,
}: IVerticalLineChart) => {
  const { id }: any = useParams();
  const accId = parseInt(getAccountId());
  const userId = getUserId();
  const [isLoading, setIsLoading] = useState(true);
  const [kpiFilter, setKpiFilter] = useState("default");
  const [kpiFilterPickerVal, setKpiFilterPickerVal] = useState(1);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState<any>("");
  const [teams, setTeams] = useState<any>({ selectedTeamId: 0, teamsData: [] });
  const [users, setUsers] = useState<any>({ selectedUserId: 0, usersData: [] });
  const [fiscalYear, setFiscalYear] = useState<any>({
    selectedFiscalYear: "",
    current_month: false,
    data: [],
    pickerData: [],
  });
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [optionUpdateBtnDisable, setOptionUpdateBtnDisable] = useState(true);
  const [isModalOpenForOptions, setIsModalOpenForOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("rename");
  const [reportTitle, setReportTitle] = useState(title ?? "");
  const [selectedDashBoard, setSelectedDashboard] = useState(0);
  const [colorGround, setColorGround] = useState({
    forGroundColor: widgetData?.foreground_color ?? "#1D3D70",
    backGroundColor: widgetData?.background_color ?? "#ffffff",
  });
  const handleModalOpenerForOptions = (option: string) => {
    setSelectedOption(option);
    setIsModalOpenForOptions(true);
  };

  //this function used for common filter
  const getSingleCardData = async (filters?: string | number | any) => {
    if (endPoint === analyticsEndpoints.CREATED_LAST_24H) {
      const response = await getCreatedLast24(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.CLOSED_LAST_24H) {
      const response = await getTicketClosedLast24(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TOTAL_COMPANY_CREATED) {
      const response = await getTotalCompanyCreated(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.LAST_WEEK_COMPANY_CREATED) {
      const response = await getLastWeekCompanyCreated(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TOP_MONTH_COMPANY_CREATION) {
      const response = await geTopMonthCompanyCreation(filters);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.AVERAGE_COMPANY_CREATION_PER_MONTH) {
      const response = await geAvgMonthCompanyCreation(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TOTAL_TASK_COUNT) {
      const response = await geTotalTaskCreation(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.LAST_WEEK_TOTAL_TASK_COUNT) {
      const response = await getLastWeekTotalTaskCount(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TOP_MONTH_TASK_ANALYTICS) {
      const response = await getTopMonthTask(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.AVG_MONTH_TASK_ANALYTICS_CARDS) {
      const response = await getAvgMonthTask(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_MAX_DEAL_AMOUNT_MONTH) {
      const response = await getMaxDealAmount(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_LOWEST_DEAL_AMOUNT_MONTH) {
      const response = await getLowestAmount(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_TOTAL_TICKET) {
      const response = await getTotalTicket(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_TOTAL_OPEN_TICKET) {
      const response = await getTotalOpenTicket(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_TOTAL_CLOSE_TICKET) {
      const response = await getTotalClosedTicket(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_TOTAL_TICKET_IN_PROGRESS) {
      const response = await getTotalProgressedTicket(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.AVERAGE_TICKET_RESOLVE_TIME) {
      const response = await getTicketResolveTime(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TICKET_CLOSE_BEFORE) {
      const response = await getCloseBefore(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TICKET_CLOSE_AFTER) {
      const response = await getCloseAfter(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }
    if (endPoint === analyticsEndpoints.KPI_TOTAL_COMPANY_TARGET) {
      const response = await getKpiTotalCompanyTarget(filters);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }
    if (endPoint === analyticsEndpoints.KPI_ACHIEVED_TILL_DATE) {
      const response = await getKpiAchievedTillDate(filters);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }
    if (endPoint === analyticsEndpoints.KPI_BACKLOG_TILL_DATE) {
      const response = await getKpiBacklogTillDate(filters);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }
    if (endPoint === analyticsEndpoints.KPI_ACHIEVED_THIS_MONTH) {
      const response = await getKpiAchievedThisMonth(filters);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        let modifyResponse = {
          data: {
            achieved_months: response.data?.achieved_this_month ?? 0,
            targeted_months: response.data.target_this_month ?? 0,
          },
        };
        setIsLoading(false);
        setData(modifyResponse);
      }
    }
    if (endPoint === analyticsEndpoints.TICKET_CREATED) {
      const response = await getTicketCreated(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }
  };
  const getSingleWidgetCardData = async (filters?: string | number | any) => {
    if (
      widgetData &&
      widgetData.others_preference?.length > 0 &&
      (widgetData.widget_name === "achieved_till_date" ||
        widgetData.widget_name === "backlog_till_date" ||
        widgetData.widget_name === "achieved_this_month")
    ) {
      setKpiFilter(widgetData.others_preference[0]?.kpiFilterName);
      setKpiFilterPickerVal(
        widgetData.others_preference[0]?.kpiFilterTypeValues ?? 1
      );
      if (widgetData.others_preference[0]?.kpiFilterTypeValues === 1) {
        getSingleCardData();
      }
      if (widgetData.others_preference[0]?.kpiFilterTypeValues === 2) {
        let teamId = widgetData.others_preference[0]?.filterByTeamID;
        if (teamId) {
          setTeams((prev: any) => ({
            ...prev,
            selectedTeamId: teamId,
          }));
          fetchKpiFilterData(teamId, "team_id");
        } else {
          getSingleCardData();
        }
      }
      if (widgetData.others_preference[0]?.kpiFilterTypeValues === 3) {
        let userId = widgetData.others_preference[0]?.filterByUserID;
        if (userId) {
          setUsers((prev: any) => ({
            ...prev,
            selectedUserId: widgetData.others_preference[0]?.filterByUserID,
          }));
          fetchKpiFilterData(
            widgetData.others_preference[0]?.filterByUserID,
            "user_id"
          );
        } else {
          getSingleCardData();
        }
      }
    } else if (
      widgetData &&
      widgetData.widget_name === "total_company_target"
    ) {
      setKpiFilter(widgetData.others_preference?.[0]?.kpiFilterName);
      if (widgetData.others_preference?.[0]?.fiscalYearValue) {
        const selectedYear = fiscalYear?.pickerData?.find(
          (year: any) =>
            year.value === widgetData.others_preference[0]?.fiscalYearValue
        );
        if (selectedYear) {
          fetchKpiFiscalFilterData(selectedYear);
        }
      } else {
        setFiscalYear((prev: any) => ({
          ...prev,
          selectedFiscalYear: "",
        }));
        getSingleCardData();
      }
    } else {
      getSingleCardData();
    }
  };

  const getSingleKpiCardData = async (filters?: any, filterTypes?: any) => {
    let obj = {
      [filterTypes]: filters,
    };
    if (endPoint === analyticsEndpoints.KPI_ACHIEVED_TILL_DATE) {
      const response = await getKpiAchievedTillDate(obj);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }
    if (endPoint === analyticsEndpoints.KPI_BACKLOG_TILL_DATE) {
      const response = await getKpiBacklogTillDate(obj);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }
    if (endPoint === analyticsEndpoints.KPI_ACHIEVED_THIS_MONTH) {
      const response = await getKpiAchievedThisMonth(obj);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        let modifyResponse = {
          data: {
            achieved_months: response.data?.achieved_this_month ?? 0,
            targeted_months: response.data.target_this_month ?? 0,
          },
        };
        setIsLoading(false);
        setData(modifyResponse);
      }
    }
  };

  const handleFilterOpeningModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  const handleWidgetDelete = async () => {
    setIsLoading(true);
    const response = await deleteWidgetFromDashboard(
      id,
      widgetData?.user_widget_association_id
        ? widgetData?.user_widget_association_id
        : 0
    );

    if (response.error === true) {
      setIsLoading(false);
      toast.error("Failed to delete widget from dashboard.");
    } else {
      window.location.reload();
      setIsLoading(false);
    }
  };

  const handleReportRename = async () => {
    setOptionUpdateBtnDisable(true);
    const response = await renameReport(
      widgetData?.user_widget_association_id as number,
      reportTitle as string
    );

    if (response.error === true) {
      toast.error("Failed to rename report.");
      setIsLoading(false);
      setOptionUpdateBtnDisable(false);
    } else {
      window.location.reload();
      setIsLoading(false);
    }
  };

  const handleReportMove = async () => {
    setOptionUpdateBtnDisable(true);
    const response = await moveReport(
      id,
      widgetData?.user_widget_association_id as number,
      selectedDashBoard
    );

    if (response.error === true) {
      toast.error("Failed to move report.");
      setIsLoading(false);
      setOptionUpdateBtnDisable(false);
    } else {
      window.location.reload();
      setIsLoading(false);
    }
  };

  const getDashboardInfo = async () => {
    const response = await getDashboardLists();
    if (response.data.length > 0) {
      let modified: any = [];
      response.data.map((data: any) => {
        return modified.push({
          value: data.id,
          label: data.dashboard_name,
        });
      });
      setDashboards(modified);
    }
  };

  const fetchFilterData = (filters: string) => {
    setIsLoading(true);
    getSingleCardData(filters);
  };
  const fetchKpiFilterData = (filters: string, filterType: string) => {
    setIsLoading(true);
    if (filterType === "team_id") {
      setTeams((prev: any) => ({
        ...prev,
        selectedTeamId: filters,
      }));
    } else {
      setUsers((prev: any) => ({
        ...prev,
        selectedUserId: filters,
      }));
    }

    getSingleKpiCardData(filters, filterType);
  };
  const fetchKpiFiscalFilterData = (filtersObj: any) => {
    setFiscalYear((prev: any) => ({
      ...prev,
      selectedFiscalYear: filtersObj.value,
    }));
    const filter: any = {
      start_date: filtersObj.start,
      end_date: filtersObj.end,
      current_month: false,
    };
    setIsLoading(true);
    getFiscalKpiData(filter);
  };
  const getFiscalKpiData = async (filters: any) => {
    if (endPoint === analyticsEndpoints.KPI_TOTAL_COMPANY_TARGET) {
      const response = await getKpiTotalCompanyTarget(filters);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData(response);
      }
    }
  };

  useEffect(() => {
    if (selectedOption === "move") {
      getDashboardInfo();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (widgetData && widgetData.widget_name === "total_company_target") {
      handleFiscalYear();
    }
  }, [widgetData]);

  useEffect(() => {
    if (
      widgetData &&
      (widgetData.widget_name === "achieved_till_date" ||
        widgetData.widget_name === "backlog_till_date" ||
        widgetData.widget_name === "achieved_this_month")
    ) {
      setKpiFilter("kpi");
      getTeamData();
      getUsersData(userId);
      getSingleWidgetCardData();
    } else if (
      widgetData &&
      widgetData.widget_name === "total_company_target"
    ) {
      setKpiFilter("fiscalYear");
      // getSingleWidgetCardData();
      getSingleCardData();
    } else if (widgetData?.date_range) {
      getSingleCardData(getLastNDaysDate(widgetData?.date_range));
    } else {
      getSingleCardData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // it's used to initial data show for total company target by fiscal year report
  useEffect(() => {
    if (widgetData && widgetData.widget_name === "total_company_target") {
      setKpiFilter(widgetData.others_preference?.[0]?.kpiFilterName);
      if (widgetData.others_preference?.[0]?.fiscalYearValue) {
        const selectedYear = fiscalYear?.pickerData?.find(
          (year: any) =>
            year.value === widgetData.others_preference[0]?.fiscalYearValue
        );

        if (selectedYear) {
          fetchKpiFiscalFilterData(selectedYear);
        }
      } else {
        setFiscalYear((prev: any) => ({
          ...prev,
          selectedFiscalYear: "",
        }));
        getSingleCardData();
      }
    }
  }, [fiscalYear?.pickerData]);

  // =========== for team
  const getTeamData = async () => {
    const response = await getTeamsOfAnUser();
    if (response.error) {
      console.log(response.error);
      return;
    }

    if (response.data?.data?.length > 0) {
      let modified: any = [];
      response.data.data.map((data: any) => {
        modified.push({
          value: data.id,
          label: data.name,
        });
      });

      setTeams((prev: any) => ({
        ...prev,
        teamsData: modified,
      }));
    } else {
      console.log("No teams data available.");
    }
  };
  // ============= users
  const getUsersData = async (userId: string) => {
    await GetALLUsers(userId)
      .then((result: any) => {
        let data: any = result.data.data.items;
        let modified: any = [];
        data.map((data: any) => {
          modified.push({
            value: data.id,
            label: data.email,
          });
        });
        setUsers((prev: any) => ({
          ...prev,
          usersData: modified,
        }));
      })
      .catch((err) => console.log(err));
  };
  // ============ fiscal year
  const handleFiscalYear = async () => {
    try {
      setIsLoading(true);
      const response: any = await getFiscalYear();
      if (response.error === true) {
        setHasError(true);
      } else {
        let modified: any = [];
        response.data.map((data: any) => {
          modified.push({
            value: data.name,
            label: data.name,
            start: data.start_date,
            end: data.end_date,
          });
        });
        setFiscalYear((prev: any) => ({
          ...prev,
          data: response.data,
          pickerData: modified,
        }));
      }
    } catch (error) {
      setHasError(true);
      console.error("Error fetching fiscal year:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isFilterModalOpen && id) {
      if (
        widgetData &&
        widgetData.others_preference?.length > 0 &&
        (widgetData.widget_name === "achieved_till_date" ||
          widgetData.widget_name === "backlog_till_date" ||
          widgetData.widget_name === "achieved_this_month")
      ) {
        setKpiFilter(widgetData.others_preference[0]?.kpiFilterName);
        setKpiFilterPickerVal(
          widgetData.others_preference[0]?.kpiFilterTypeValues ?? 1
        );
        if (widgetData.others_preference[0]?.kpiFilterTypeValues === 1) {
          getSingleCardData();
        }
        if (widgetData.others_preference[0]?.kpiFilterTypeValues === 2) {
          setTeams((prev: any) => ({
            ...prev,
            selectedTeamId: widgetData.others_preference[0]?.filterByTeamID,
          }));
          fetchKpiFilterData(
            widgetData.others_preference[0].filterByTeamID,
            "team_id"
          );
        }
        if (widgetData.others_preference[0]?.kpiFilterTypeValues === 3) {
          setUsers((prev: any) => ({
            ...prev,
            selectedUserId: widgetData.others_preference[0]?.filterByUserID,
          }));
          fetchKpiFilterData(
            widgetData.others_preference[0].filterByUserID,
            "user_id"
          );
        }
      } else if (
        widgetData &&
        widgetData.widget_name === "total_company_target"
      ) {
        setKpiFilter(widgetData.others_preference?.[0]?.kpiFilterName);
        if (widgetData.others_preference?.[0]?.fiscalYearValue) {
          const selectedYear = fiscalYear?.pickerData?.find(
            (year: any) =>
              year.value === widgetData.others_preference[0]?.fiscalYearValue
          );

          if (selectedYear) {
            fetchKpiFiscalFilterData(selectedYear);
          }
        } else {
          setFiscalYear((prev: any) => ({
            ...prev,
            selectedFiscalYear: "",
          }));
          getSingleCardData();
        }
      }
    }
  }, [isFilterModalOpen, id, widgetData]);

  return (
    <>
      <Modal
        className="__generic_modal_v2"
        autoFocus
        show={isModalOpenForOptions}
        onHide={() => {
          setIsModalOpenForOptions(false);
        }}
        backdrop={"static"}
        size="lg"
      >
        <Modal.Header className="modal-header-custom">
          <Modal.Title>
            {selectedOption === "rename"
              ? `Rename your dashboard`
              : "Move your report"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0 40px 1px", minHeight: "180px" }}>
          {selectedOption === "rename" && (
            <>
              <div className="title_for_rt">Rename this report *</div>
              <Input
                value={reportTitle}
                onChange={(eve) => {
                  if (optionUpdateBtnDisable) {
                    setOptionUpdateBtnDisable(false);
                  }
                  setReportTitle(eve);
                }}
                style={{ marginTop: "10px", padding: "10px" }}
              />
            </>
          )}

          {selectedOption === "move" && (
            <>
              <div className="title_for_rt">Move this report *</div>
              <SelectPicker
                style={{
                  marginTop: "15px",
                  width: "320px",
                }}
                data={dashboards}
                cleanable={false}
                onSelect={(value) => {
                  if (optionUpdateBtnDisable) {
                    setOptionUpdateBtnDisable(false);
                  }
                  setSelectedDashboard(value);
                }}
              />
            </>
          )}

          <Divider />
          <div
            className="actions"
            style={{ display: "flex", gap: "10px", marginBottom: "25px" }}
          >
            <Button
              color="green"
              appearance="default"
              disabled={optionUpdateBtnDisable}
              onClick={() => {
                if (selectedOption === "rename") handleReportRename();
                if (selectedOption === "move") handleReportMove();
              }}
            >
              {selectedOption === "rename" ? "rename" : "Move"}
            </Button>
            {/* <Button color="orange" onClick={() => {}}>
              cancel
            </Button> */}
          </div>
        </Modal.Body>
      </Modal>

      <FilterModal
        isFilterModalOpen={isFilterModalOpen}
        handleFilterOpening={handleFilterOpeningModal}
        title={description}
        fetchFilterData={fetchFilterData}
        isLoading={isLoading}
        rangeValue={widgetData?.date_range || 30}
        chart={
          <>
            <div className="single_card_data">
              <div className="mt-4">
                {data?.data &&
                  String(data?.data["" + Object.keys(data?.data)[0]])}
              </div>
            </div>
          </>
        }
        source={source}
        widgetData={widgetData}
        isSingleCard={true}
        colorGround={colorGround}
        setColorGround={setColorGround}
        teamsData={teams}
        users={users}
        fetchKpiFilterData={fetchKpiFilterData}
        getSingleCardData={getSingleCardData}
        kpiFilter={kpiFilter}
        fiscalPickerData={fiscalYear}
        fetchKpiFiscalFilterData={fetchKpiFiscalFilterData}
        kpiPickerValues={kpiFilterPickerVal}
      />

      <div
        className={
          source === "report"
            ? "__vl_single_card_container"
            : "__vl_single_card_container_dashboard"
        }
      >
        <div className="__title_with_option">
          <div
            className="__container"
            style={{
              minHeight: "35px",
              backgroundColor: "#F2F7FF",
              paddingInline: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="__table_snip_container_title"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                display: "flex",
                gap: "10px",
              }}
            >
              {id !== undefined && (
                <div className="drag">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 3C6.27614 3 6.5 2.77614 6.5 2.5C6.5 2.22386 6.27614 2 6 2C5.72386 2 5.5 2.22386 5.5 2.5C5.5 2.77614 5.72386 3 6 3Z"
                      stroke="#1D3D70"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 6.5C6.27614 6.5 6.5 6.27614 6.5 6C6.5 5.72386 6.27614 5.5 6 5.5C5.72386 5.5 5.5 5.72386 5.5 6C5.5 6.27614 5.72386 6.5 6 6.5Z"
                      stroke="#1D3D70"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 10C6.27614 10 6.5 9.77614 6.5 9.5C6.5 9.22386 6.27614 9 6 9C5.72386 9 5.5 9.22386 5.5 9.5C5.5 9.77614 5.72386 10 6 10Z"
                      stroke="#1D3D70"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 3C9.77614 3 10 2.77614 10 2.5C10 2.22386 9.77614 2 9.5 2C9.22386 2 9 2.22386 9 2.5C9 2.77614 9.22386 3 9.5 3Z"
                      stroke="#1D3D70"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 6.5C9.77614 6.5 10 6.27614 10 6C10 5.72386 9.77614 5.5 9.5 5.5C9.22386 5.5 9 5.72386 9 6C9 6.27614 9.22386 6.5 9.5 6.5Z"
                      stroke="#1D3D70"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 10C9.77614 10 10 9.77614 10 9.5C10 9.22386 9.77614 9 9.5 9C9.22386 9 9 9.22386 9 9.5C9 9.77614 9.22386 10 9.5 10Z"
                      stroke="#1D3D70"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.5 3C2.77614 3 3 2.77614 3 2.5C3 2.22386 2.77614 2 2.5 2C2.22386 2 2 2.22386 2 2.5C2 2.77614 2.22386 3 2.5 3Z"
                      stroke="#1D3D70"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.5 6.5C2.77614 6.5 3 6.27614 3 6C3 5.72386 2.77614 5.5 2.5 5.5C2.22386 5.5 2 5.72386 2 6C2 6.27614 2.22386 6.5 2.5 6.5Z"
                      stroke="#1D3D70"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.5 10C2.77614 10 3 9.77614 3 9.5C3 9.22386 2.77614 9 2.5 9C2.22386 9 2 9.22386 2 9.5C2 9.77614 2.22386 10 2.5 10Z"
                      stroke="#1D3D70"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              )}

              <div title={title}>
                {layoutsPassToTemplate
                  ? layoutsPassToTemplate.w > 2
                    ? title
                    : `${title?.slice(0, 14)}...`
                  : title}
              </div>
            </div>
            <SnippetsHeaderActions
              handleFilterOpening={handleFilterOpeningModal}
              handleWidgetDelete={handleWidgetDelete}
              handleReportReload={getSingleCardData}
              handleModalOpenerForOptions={handleModalOpenerForOptions}
              showFilter={showFilter}
            />
            {/* <SnippetsHeaderActions
              handleFilterOpening={handleFilterOpeningModal}
              handleWidgetDelete={handleWidgetDelete}
              handleReportReload={getHorizontalData}
              handleModalOpenerForOptions={handleModalOpenerForOptions}
            /> */}
          </div>
        </div>
        <div
          style={
            colorGround?.backGroundColor
              ? {
                  paddingInline: "25px",
                  height: "100%",
                  backgroundColor: colorGround.backGroundColor,
                }
              : { paddingInline: "25px", height: "100%" }
          }
        >
          {isLoading && !hasError && (
            <LoadingComponent
              message="Fetching Data"
              resizableCardLoader={true}
            />
          )}
          {!isLoading && hasError && <HasError />}
          {!isLoading && !hasError && data && (
            <div
              className="single_card_data"
              style={
                colorGround?.forGroundColor
                  ? { color: colorGround.forGroundColor }
                  : {}
              }
            >
              <div className="mt-4">
                {" "}
                {data?.data &&
                  String(data?.data["" + Object.keys(data?.data)[0]])}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleCard;
