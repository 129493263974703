// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__micro_card_container {
  margin: 7px;
  max-height: 295px;
  border-radius: 8px;
  border: 1px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(145, 92, 182, 0.2);
}
.__micro_card_container ._element {
  padding: 25px 0 107px 30px;
}
.__micro_card_container ._element .title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  color: #05004e;
}
.__micro_card_container ._element .value {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  line-height: 87px;
  letter-spacing: 0px;
  color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/micro/Cards/cards.scss"],"names":[],"mappings":"AAAA;EAEE,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,uBAAA;EACA,8CAAA;AAAF;AACE;EACE,0BAAA;AACJ;AACI;EACE,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AACN;AAEI;EACE,oBAAA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AADN","sourcesContent":[".__micro_card_container {\n  // width: 446px;\n  margin: 7px;\n  max-height: 295px;\n  border-radius: 8px;\n  border: 1px;\n  background-color: white;\n  box-shadow: 0 5px 15px rgba(145, 92, 182, 0.2);\n  ._element {\n    padding: 25px 0 107px 30px;\n\n    .title {\n      font-family: Poppins;\n      font-size: 16px;\n      font-weight: 500;\n      line-height: 32px;\n      letter-spacing: 0px;\n      color: #05004e;\n    }\n\n    .value {\n      font-family: Poppins;\n      // font-size: 62px;\n      font-size: 25px;\n      font-weight: 600;\n      line-height: 87px;\n      letter-spacing: 0px;\n      color: #000000;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
